* {
    margin: 0;
    padding: 0;
    /* text-align: left; */
}

body {
    font: 16px Helvetica, Sans-Serif;
    line-height: 24px;
}

.clear {
    clear: both;
}

#page-wrap {
    width: 800px;
    margin: 0Spx auto 60px;
}

#pic {
    float: right;
    margin: -30px 0 0 0;
    padding-top: 90px;
    max-width: 300px;
    max-height: 300px;
}

img {
    height: 400px;
    /* width: 200px; */
}

h1 {
    margin: 0 0 16px 0;
    padding: 0 0 16px 0;
    font-size: 42px;
    font-weight: bold;
    letter-spacing: -2px;
    border-bottom: 1px solid #999;
}

h2 {
    font-size: 20px;
    margin: 0 0 6px 0;
    position: relative;
}

h2 span {
    position: absolute;
    bottom: 0;
    right: 0;
    font-style: italic;
    font-family: Georgia, Serif;
    font-size: 16px;
    color: #999;
    font-weight: normal;
}

p {
    margin: 0 0 16px 0;
}

a {
    color: #999;
    text-decoration: none;
    border-bottom: 1px dotted #999;
}

a:hover {
    border-bottom-style: solid;
    color: black;
}

ul {
    margin: 0 0 32px 17px;
}

#objective {
    width: 500px;
    float: left;
}

#objective p {
    font-family: Georgia, Serif;
    font-style: italic;
    color: #666;
}

dt {
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    text-align: right;
    padding: 0 26px 0 0;
    width: 150px;
    float: left;
    height: 100px;
    border-right: 1px solid #999;
}

dd {
    width: 600px;
    float: right;
}

dd.clear {
    float: none;
    margin: 0;
    height: 15px;
}