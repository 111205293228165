.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* a {
    color: black;
} */

.body {
    width: 800px;
    margin-right: auto;
    margin-left: auto;
}

.navigation {
    margin-top: 10px;
    margin-bottom: 40px;
    display: flex;
}

.sam {
    width: calc(100% - 280px);
    text-align: left;
    font-size: 26px;
}

.navigation-sub {
    width: 280px;
    display: flex;
    text-align: right;
}

.nav-item {
    text-align: right;
}

.nav-space {
    margin-right: 22px;
}

.active {
    color: black;
    font-weight: bold;
    text-decoration: none !important;
    border: none !important;
    margin-bottom: -10px;
}

.card {
    width: 100%;
    height: 300px;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    box-shadow: 0 0 20px hsla(0, 0%, 85.1%, .2);
    margin-bottom: 50px;
}

.project-image {
    width: 358px;
    height: 223px;
}

.image-icons {
    width: 358px;
    height: 223px;
    object-fit: cover;
    overflow: hidden;
}

.social {
    /* position: absolute; */
    margin-top: 20px;
    display: flex;
    bottom: 60px;
}

.social i {
    padding-right: 30px;
    font-size: 25px;
    color: #9a9b9c;
}

.social i:hover {
    color: #ffffff;
}

.Projects {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.left {
    margin-top: 20px;
    width: 360px;
}

.right {
    margin-top: 20px;
    width: 360px;
}

.social-bar {
    display: flex;
    justify-content: center;
    width: 100%;
}

.social {
    text-align: center;
    color: black;
    /* width: 32px;
    height: 32px; */
    /* padding: 8px 8px; */
    vertical-align: 0px;
}

.nounderline {
    text-decoration: none;
    border-color: transparent;
}

.social-margin {
    margin-right: 8px;
}

.copyright {
    margin-top: 10px;
}

.footer {
    margin-top: 50px;
    margin-bottom: 50px;
}

figure {
    margin-bottom: 30px;
}


/* For About.jsx */

h2 {
    text-align: justify;
}

p {
    text-align: justify;
}